<template>
  <div class="smart-wrap postView">
    <div class="smart-wrap" style="padding: 40px;">
      <h2 class="col-md-12">Social Media Post</h2>
      <div class="input-wrap col-md-4">
        <label for="">Post Status</label>
        <select name="" id="" v-model="statusPost" v-on:change="getList(statusPost)">
          <option value="Waiting for Approval">Waiting for Approval</option>
          <option value="Approved">Approved</option>
        </select>
      </div>
    </div>
    <div class="smart-wrap" v-if="mode =='preview'">
      <div class="element-wrap col-md-6" v-for="(post, index) in list" :key="index">
        <div class="envelop" >
          <img v-if="post.publishImg && post.publishImg[0]" :src="post.publishImg[0].url" alt="">
          <img v-else src="https://via.placeholder.com/700" alt="">
          <h4>{{post.name}} </h4>
          <span> Publish: <strong>{{ formatData('date', post.startDate)[0] +' '+formatData('date', post.startDate)[1]}}</strong> </span>
          <p>{{post.description}}</p>
          <div class="wrap">
            <p>
              <UiButton color="green" text="Edit" position="left" style="width: calc( 25% - 20px ); margin: 10px" data-toggle="modal" data-target="#modal-update" v-on:click="setgRecord([JSON.parse(JSON.stringify(post)), 'update'])"/>
              <UiButton color="gray" text="Comments" position="left" style="width: calc( 25% - 20px ); margin: 10px" data-toggle="collapse" :href="'#collapse-'+post.SK" role="button" aria-expanded="false" :aria-controls="'collapse-'+post.SK"/>
              <router-link :to="{ path:'/smartmaker',query: { name:'SMLUP', PK:post.layout.PK, SK:post.layout.SK, post:post.SK} }" target="_blank">
                <UiButton color="gray" text="Design" position="left" style="width: calc( 25% - 20px ); margin: 10px"/>
              </router-link>
              <UiButton v-if="statusPost == 'Waiting for Approval'" color="blue" text="Approve" position="left" style="width: calc( 25% - 20px ); margin: 10px" v-on:click="updateAttPost(post, 'Approved')"/>
              <UiButton v-else color="blue" text="Disapprove" position="left" style="width: calc( 253% - 20px ); margin: 10px" v-on:click="updateAttPost(post, 'Waiting for Approval')"/>
            </p>
            <div class="collapse" :id="'collapse-'+post.SK">
              <div class="card card-body smart-wrap">
                <div class="input-wrap">
                  <label for="">Comments</label>
                  <textarea name="" id="" cols="30" rows="5" v-model="post.comments"></textarea>
                  <UiButton color="green" text="Save" position="center" style="width: 250px; margin: 10px auto" v-on:click="updateAttPost(post, 'comment')" data-toggle="collapse" :href="'#collapse-'+post.SK" role="button" aria-expanded="false" :aria-controls="'collapse-'+post.SK"/>
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="smart-wrap" v-if="mode =='design'">
      <div :class="{'element-wrap ': true, 'col-md-6':postIn != index, 'col-md-12':postIn == index}" :id="'post-'+index" v-for="(post, index) in list" :key="index">
        <div class="envelop">
          <div class="smart-wrap" style="width: auto;">
            <div v-if="postIn == index" style="width: 1040px;">
              <paper-layout 
                id="postHtml"
                v-if="post.savedLayouts"
                :style="'margin:0 auto; padding:20px; width:1000px; height: 1000px; overflow: hidden;'"
                :idioms="['en']" 
                :language="'en'" 
                :layout="post.savedLayouts[0].versions[0].layout" 
                :models="{}" 
                :palette="post.savedLayouts[0].palette[0]" 
                :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"
              />
              <img v-else src="https://via.placeholder.com/1000" alt="" srcset="" style="max-width: 1000px;">
            </div>
            <img v-else-if="post.publishImg && post.publishImg[0]" :src="post.publishImg[0].url" alt="" srcset="">
            <img v-else src="https://via.placeholder.com/1000" alt="" srcset="">  
            <div style="width: 260px;display: flex;flex-direction: column;flex-wrap: nowrap;justify-content: space-between;" v-if="index == postIn">
              <div class="wrap">
                <UiButton color="red" text="Cancel" position="center" style="width: 250px; margin: 10px" v-on:click=" postIn = undefined "/>
                <UiButton color="green" text="Randomize" position="center" style="width: 250px; margin: 10px" v-on:click="generatePost(post, index) "/>
                <UiButton color="gray" text="Color" position="center" style="width: 250px; margin: 10px" v-on:click="randomColor(index) "/>
                <UiButton color="gray" text="Reverse Colors" position="center" style="width: 250px; margin: 10px" v-on:click="reversePalette(index)"/>
                <UiButton color="gray" text="Fonts" position="center" style="width: 250px; margin: 10px" v-on:click="randomFonts(index)" />
                <UiButton color="gray" text="Logo" position="center" style="width: 250px; margin: 10px" v-on:click="randomLogo(index)"/>
                <UiButton color="gray" text="Image" position="center" style="width: 250px; margin: 10px" v-on:click="randomImage(index)" />
                <UiButton color="gray" text="Texture" position="center" style="width: 250px; margin: 10px" v-on:click="randomTexture(index)"/>
                <div class="input-wrap" style="display: block; float: right;">
                  <div class="check-wrap" style="display: inline;" >
                    <div class="envelop" style="display: flex; justify-content: center;" >
                      <input type="checkbox" class="box" v-model="viewTextureValue" v-on:click="viewTexture(viewTextureValue, index)"/>
                      <span class="text" > Texture </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wrap"  v-if="index == postIn">
                <UiButton color="blue" text="Save Changes" position="left" style="width: 250px; margin: 10px" v-on:click="saveVersions(post, index)"/>
                <UiButton color="green" text="Edit Text" position="left" style="width: 250px; margin: 10px" v-on:click="editPost([JSON.parse(JSON.stringify(post)), 'update']);"/>
                <router-link v-if="post.savedLayouts[0].SML" :to="{ path:'/smartmaker',query: { name:'SMLUP', PK:post.savedLayouts[0].SML.PK, SK:post.savedLayouts[0].SML.SK, post:post.SK} }" target="_blank">
                  <UiButton color="gray" text="Advanced Design" position="left" style="width: 250px; margin: 10px"/>
                </router-link>
                <UiButton color="gray" text="Comments" position="left" style="width: 250px; margin: 10px" data-toggle="collapse" :href="'#collapse-'+post.SK" role="button" aria-expanded="false" :aria-controls="'collapse-'+post.SK"/>
                <UiButton v-if="statusPost == 'Waiting for Approval'" color="blue" text="Approve" position="left" style="width: 250px; margin: 10px" v-on:click="updateAttPost(post, 'Approved')"/>
                <UiButton v-else color="blue" text="Disapprove" position="left" style="width: 250px; margin: 10px" v-on:click="updateAttPost(post, 'Waiting for Approval')"/>
              </div>
            </div>
          </div>
          
          <h4>{{post.name}} </h4>
          <span> Publish: <strong>{{ formatData('date', post.startDate)[0] +' '+formatData('date', post.startDate)[1]}}</strong> </span>
          <p>{{post.description}}</p>
          <div class="wrap" style="max-width: 1000px;"  v-if="index != postIn">
            <p>
              <UiButton color="green" text="Edit" position="left" style="width: calc( 25% - 20px ); margin: 10px" data-toggle="modal" data-target="#modal-update" v-on:click="setgRecord([JSON.parse(JSON.stringify(post)), 'update'])"/>
              <UiButton color="gray" text="Comments" position="left" style="width: calc( 25% - 20px ); margin: 10px" data-toggle="collapse" :href="'#collapse-'+post.SK" role="button" aria-expanded="false" :aria-controls="'collapse-'+post.SK"/>

              <UiButton v-if="!post.savedLayouts || post.savedLayouts == ''" color="gray" text="Generate Post" position="left" style="width: calc( 25% - 20px ); margin: 10px" v-on:click=" generatePost(post, index); postIn = index;"/>
              <UiButton v-else  color="gray" text="Design" position="left" style="width: calc( 25% - 20px ); margin: 10px" v-on:click="postIn = index; setWebSite(post.savedLayouts[0])"/>

              <UiButton v-if="statusPost == 'Waiting for Approval'" color="blue" text="Approve" position="left" style="width: calc( 25% - 20px ); margin: 10px" v-on:click="updateAttPost(post, 'Approved')"/>
              <UiButton v-else color="blue" text="Disapprove" position="left" style="width: calc( 253% - 20px ); margin: 10px" v-on:click="updateAttPost(post, 'Waiting for Approval')"/>
            </p>
            <div class="collapse" :id="'collapse-'+post.SK">
              <div class="card card-body smart-wrap">
                <div class="input-wrap">
                  <label for="">Comments</label>
                  <textarea name="" id="" cols="30" rows="5" v-model="post.comments"></textarea>
                  <UiButton color="green" text="Save" position="center" style="width: 250px; margin: 10px auto" v-on:click="updateAttPost(post, 'comment')" data-toggle="collapse" :href="'#collapse-'+post.SK" role="button" aria-expanded="false" :aria-controls="'collapse-'+post.SK"/>
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </div>
    </div>
    <paper-modal v-if="postIn == undefined"/>
  </div>
</template>
<script>
  //Tools
  import { API, Storage, Auth} from 'aws-amplify'
  import Vuex from "vuex";
  import Swal from 'sweetalert2'
  import { calendar, loading, popUp, formatData, processLayout} from '../../store/tools'
  
  //Components
  import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
  import PaperModal from '../../components/papers/PaperModal.vue'
  import PaperLayout from '../../components/papers/PaperLayout.vue'
  import UiButton from '../../components/ui/UiButton.vue';

  //GRAPHQL
  import { smd_updateAttributes  } from '../../graphql/mutations'
  import { smd_listEntityObj, smd_getSMLItemByName , smd_generateGraphicPost } from '../../graphql/queries'
  import { onUpdateSmartRecord } from '../../graphql/subscriptions'

  export default {
    name:'checkout',
    components:{
      PaperDisplayer,
      UiButton,
      PaperLayout,
      PaperModal
    },
    data() {
      return {
        list:[],
        statusPost: 'Waiting for Approval',
        comments:{},
        mode:'preview',
        postIn: undefined,
        viewTextureValue: true,
      }
    },
    created() {
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      loading('show')
      this.setUserWeb()
      this.subscribe();
      /*if (this.reload == true){
        this.setStatusReload(false);
        location.reload();
      }*/
      
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData']),
      ...Vuex.mapMutations(['setWebSite','setStatusReload','setEntities','setPublicOrganizationData','setPublicOrgPay','setPublicOrganization','setContactSK','setWebSite','SetUser','setgRecord','SetOrganizationID']),
      formatData(format, data){
        return formatData(format, data)
      },
      //View  
      async setUserWeb(){
        console.time()
        loading('show')
        if (this.$router.currentRoute.value.query.mode) {
          this.mode = this.$router.currentRoute.value.query.mode
        }
        if (this.user = 'Not authorized') {
          this.getList(this.statusPost)
        } else {
          Auth.signIn(this.$router.currentRoute.value.query.user, this.$router.currentRoute.value.query.pass)
          .then((user) => { 
            this.SetUser(user.username)
            this.getList(this.statusPost)
            console.timeEnd()
            this.setEntities({
              MPR:{
                allowSelectPK:false
              }
            })
          })
          .catch((err) => popUp(err, 'response', 'err'));
        }
      },
      async getList(status){
        //console.clear()
        loading('show')
        console.time()
        await this.getPublicOrganization()//Alias
        console.timeEnd()
        console.time()
        
        this.SetOrganizationID(this.publicOrganization)
        try {
          let pullData = await API.graphql({
            query: smd_listEntityObj,
            variables: {
              active: '1',
              PK: this.publicOrganization,
              shortEntity: 'MPR',
              pageSize: 0,
              index: 3,
              group: status,
            }
          })
          pullData = pullData.data.smd_listEntityObj
          let list = JSON.parse(pullData.data)
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            const att = list[index].attributes
            for (const key in list[index].attributes) {
              try {
                if (att[key].attributeType == 'L') {
                  list[index][key] = JSON.parse(att[key].attributeValue)
                } else {
                  list[index][key] = att[key].attributeValue
                }
              } catch (error) {
                console.log(key ,att[key].attributeValue);
                
              }
            }
          }
          console.log(list);
          this.list= list
          
        } catch (error) {
          console.log(error);
        }
        console.timeEnd()
        loading('hidden')
      },
      async updateAttPost(data, action){
        loading('show')
        let pullData=''
        try {
          let pushData = [{
            attributeName: 'status',
            attributeValue: action,
            attributeType: 'S',
          }]
          if (action == 'comment') {
            pushData[0].attributeValue = data.comments
            pushData[0].attributeName = 'comments'
          }
          console.log(pushData);
          pullData = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
              PK: data.PK,
              SK: data.SK ,
              attributes: JSON.stringify(pushData) ,
            },
          });
          pullData = pullData.data.smd_updateAttributes
          this.getList(this.statusPost)
        } catch (error) {
          loading('hidden')
          console.log(error);
          popUp(error, 'response', 'err')
        }
      },
      editPost(value){
        this.postIn = undefined

        setTimeout(() => {
          
          this.setgRecord(value)
          $('#modal-update').modal('toggle')
          $('#modal-update').modal('show')
        }, 100);
      },
      randomColor(index){
        //console.clear()
        console.log(this.list[index].savedLayouts[0].palette);
        let palette = this.list[index].savedLayouts[0].palette[0]
        do {
          let random = Math.floor(Math.random() * this.list[index].savedLayouts[0].palette.length);
          if (this.list[index].savedLayouts[0].palette[random]) {
            palette = this.list[index].savedLayouts[0].palette[random]
          }
        } while (!palette);
        palette[5] = "#000"
        palette[6] = "#FFF"
        console.log(palette);
        this.list[index].savedLayouts[0].palette.splice( this.list[index].savedLayouts[0].palette.indexOf(palette) , 1) 
        this.list[index].savedLayouts[0].palette.splice(0,0, palette) 
      },
      reversePalette(index){
        console.clear()
        let palette = this.list[index].savedLayouts[0].palette[0]
        palette = palette.splice(0,5).reverse()
        palette[5] = "#000"
        palette[6] = "#FFF"
        this.list[index].savedLayouts[0].palette[0] =palette
      },
      randomFonts(index){
        //console.clear()
        if (!this.list[index].savedLayouts[0].fonts) {
          this.list[index].savedLayouts[0].fonts = {Title: "'Marck Script'", SubTitle: "'Montserrat'", Text: "'Montserrat'", SubText: "'Montserrat'"}
        }
        let font = ''
        do {
          let random = Math.floor(Math.random() * this.comboFonts.length);
          if (this.comboFonts[random]) {
            font = this.comboFonts[random]
          }
        } while (!font);

        this.list[index].savedLayouts[0].fonts= font
        this.setWebSite(this.list[index].savedLayouts[0])
      },
      randomImage(index){
        //console.clear()
        let images = this.list[index].savedLayouts[0].images
        if (images.length == 0) {
          processLayout( this.list[index].savedLayouts[0].versions[0].layout, 'noneImg' , null)
        }else{
          images.splice(0, 0, images.slice(-1)[0])
          console.log( this.list[index].savedLayouts[0]);
          this.list[index].savedLayouts[0].images = images.slice(0,-1)
          processLayout( this.list[index].savedLayouts[0].versions[0].layout, 'randomImage' , this.list[index].savedLayouts[0].images[0])
        }
      },
      randomTexture(index){
        //console.clear()
        let textures = this.list[index].savedLayouts[0].textures
        textures.splice(0, 0, textures.slice(-1)[0])
        this.list[index].savedLayouts[0].textures = textures.slice(0,-1)
        processLayout( this.list[index].savedLayouts[0].versions[0].layout, 'randomTexture' , this.list[index].savedLayouts[0].textures[0])
      },
      randomLogo(index){
        //console.clear()
        let logos = this.list[index].savedLayouts[0].logos
        logos.splice(0, 0, logos.slice(-1)[0])
        this.list[index].savedLayouts[0].logos = logos.slice(0,-1)
        processLayout( this.list[index].savedLayouts[0].versions[0].layout, 'randomLogo' , this.list[index].savedLayouts[0].logos[0])
      },
      viewTexture(value, index){
        //console.clear()
        processLayout( this.list[index].savedLayouts[0].versions[0].layout, 'viewTexture' , value)
        
      },
      async generatePost(post, vIndex){
        let pullData = ''
        let pushData ={
          itemPK:post.PK,
          itemSK:post.SK,
          SMLPK:null,
          SMLSK:null,
        }
        console.log(pushData);
        try {
          pullData = await API.graphql({
            query: smd_generateGraphicPost,
            variables:pushData
          })
          pullData = pullData.data.smd_generateGraphicPost
          let post = null
          post =JSON.parse(pullData.data)
          let att = JSON.parse(pullData.additionalData)
          console.log(att);
          
          for (let index = 0; index < post.length; index++) {
            const element = post[index];
            post[index].palette = element.palette
            if (typeof element.palette == 'string') {
              post[index].palette = JSON.parse(element.palette)
            }
            if (typeof element.logoSelection == 'string') {
              post[index].logoSelection = JSON.parse(element.logoSelection)
            }
            if (typeof element.textures == 'string') {
              post[index].textures = JSON.parse(element.textures)
            }
            if (typeof element.images == 'string') {
              post[index].images = JSON.parse(element.images)
            }
            
            for (let zindex = 0; zindex < element.versions.length; zindex++) {
              const version = element.versions[zindex];
              post[index].versions[zindex].layout = version.layout
              if (typeof version.layout == 'string') {
                post[index].versions[zindex].layout = JSON.parse(version.layout)
              }
            }
          }
          post[0].fonts = JSON.parse(att.fonts)
          post[0].SML = att.sml
          console.log(post);
          this.setWebSite(post[0])
          if (this.list[vIndex].savedLayouts) {
            this.list[vIndex].savedLayouts[0] = post[0]
          }else{
            this.list[vIndex].savedLayouts = post
          }
          this.randomImage(vIndex);
          this.randomTexture(vIndex);
          this.randomColor(vIndex);
          this.randomFonts(vIndex);
        } catch (error) {
          popUp(error, 'cach', 'err')
          console.log(error);
        }
      },
      async saveVersions(post, vIndex){
        let push = '' 
        let layout = ''
        //post.savedLayouts[0].html =document.getElementById('postHtml').innerHTML
        console.log(post.savedLayouts[0]);
        layout = [{
          attributeName: 'savedLayouts',
          attributeValue: JSON.stringify(post.savedLayouts),  
          attributeType: 'L',
        }]
        try {
          push = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
              PK: post.PK,
              SK: post.SK ,
              attributes: JSON.stringify(layout) ,
            },
          });
          push = push.data.smd_updateAttributes
          console.log(post.savedLayouts);
          this.getList(this.statusPost)
        } catch (error) {
          popUp(error, 'cach', 'err')
          console.log(error);
        }
        this.showVersions = true 
      },
      subscribe() { 
        API.graphql({ query: onUpdateSmartRecord }).subscribe({
          next: (eventData) => {
            try {
              console.clear()
              this.subData = null
              let subData = eventData.value.data.onUpdateSmartRecord;
              subData = JSON.parse(subData.data)
              if (subData.PK == this.organizationID ) {
                if (subData.shortEntity == 'MPR' && subData.attributes.status.attributeValue ==  this.statusPost) {
                  this.getList(this.statusPost)
                }
              }
            } catch (error) {
              popUp(error, 'response', 'err')
            }
          }
        });
      },
    },
    computed:{
      ...Vuex.mapState(['publicOrganization','entities', 'publicOrganizationData','publicOrganization', 'reload', 'user','publicOrgPay','dataUser','quiz','organizationData','calAlert','organizationID','srcFonts','comboFonts'])
    }
  }
</script>